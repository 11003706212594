import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Gamarnika from "./gamarnika";
import Syrganova from "./syrganova";

const App = () => {
  return (
    <>
      <Router>
        <Route exact path="/gamarnika" component={Gamarnika} />
        <Route exact path="/syrganova" component={Syrganova} />
      </Router>
    </>
  );
};

export default App;
