import React from "react";

const Header = () => {
  return (
    <header>
      <a href="https://dominos.by" target="_blank" rel="noopener noreferrer">
        <img className="logo" src="/img-9ef03a.png" alt="logo" />
      </a>

      <div className="delivery-container">
        <img src="/img-dc81e2.svg" alt="clock" />
        <p>30 минут доставка</p>
      </div>
      <div className="phone-container">
        <img src="/img-71a76b.png" alt="phone" />
        <a href="tel:7717">7717</a>
      </div>
      <style>{`
        header {
            display: grid;
            align-items: center;
            grid-auto-flow: column;

            max-width: 1366px;
            width: 100%;
            margin: 0 auto;
            padding: 0 5vw;
            box-sizing: border-box;
        }

        .delivery-container,
        .phone-container {
            display: grid;
            column-gap: 12px;
            align-items: center;
            grid-template-columns: auto 1fr;

            font-family: 'Ubuntu', sans-serif;
            font-size: 24px;
            color: #00537a;
            line-height: 1em;
            font-weight: 700;
        }

        .logo {
            max-width: 150px;
            width: 100%;
            padding-bottom: 12px;
        }

        .delivery-container img,
        .phone-container img {
            width: 24px;
            height: 24px;
        }

        a {
            text-decoration: none;
            color: #00537a;
        }

        p {
            margin: 0;
        }

        @media (max-width: 768px) {
            .delivery-container {
                display: none;
            }

            .phone-container {
                column-gap: 12px;
                justify-self: flex-end;
                font-size: 16px;
            }
        }
      `}</style>
    </header>
  );
};

export default Header;
