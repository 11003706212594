import React, { useEffect } from "react";
import AwesomeSlider from "react-awesome-slider";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import Loader from "./loader";
import "react-awesome-slider/dist/styles.css";
import "react-awesome-slider/dist/custom-animations/scale-out-animation.css";

import Header from "./header";

const AutoplaySlider = withAutoplay(AwesomeSlider);

const Gamarnika = () => {
  useEffect(() => {
    handler();
    window.addEventListener("resize", handler);
  }, []);

  const handler = () => {
    document.documentElement.style.setProperty(
      "--vh",
      `${window.innerHeight * 0.01}px`
    );
  };

  return (
    <>
      <Loader />
      <section className="wrapper">
        <Header />
        <h1>Так будет выглядеть новый Domino`s по адресу: Гамарника 19</h1>
        <div className="slider-container">
          <i className="prev" />
          <AutoplaySlider
            className="slider"
            animation="scaleOutAnimation"
            play={true}
            cancelOnInteraction={true}
            interval={4000}
          >
            <div data-src="/gamarnika/ACCamera.jpg" />
            <div data-src="/gamarnika/ACCamera_1.jpg" />
            <div data-src="/gamarnika/ACCamera_2.jpg" />
            <div data-src="/gamarnika/ACCamera_3.jpg" />
            <div data-src="/gamarnika/ACCamera_4.jpg" />
            <div data-src="/gamarnika/ACCamera_5.jpg" />
            <div data-src="/gamarnika/ACCamera_6.jpg" />
            <div data-src="/gamarnika/ACCamera_7.jpg" />
            <div data-src="/gamarnika/ACCamera_8.jpg" />
            <div data-src="/gamarnika/ACCamera_9.jpg" />
            <div data-src="/gamarnika/ACCamera_10.jpg" />
            <div data-src="/gamarnika/ACCamera_11.jpg" />
            <div data-src="/gamarnika/ACCamera_12.jpg" />
            <div data-src="/gamarnika/ACCamera_13.jpg" />
            <div data-src="/gamarnika/ACCamera_14.jpg" />
            <div data-src="/gamarnika/ACCamera_15.jpg" />
            <div data-src="/gamarnika/ACCamera_16.jpg" />
            <div data-src="/gamarnika/ACCamera_17.jpg" />
            <div data-src="/gamarnika/ACCamera_18.jpg" />
            <div data-src="/gamarnika/ACCamera_19.jpg" />
            <div data-src="/gamarnika/ACCamera_20.jpg" />
            <div data-src="/gamarnika/ACCamera_21.jpg" />
            <div data-src="/gamarnika/ACCamera_22.jpg" />
          </AutoplaySlider>
          <i className="next" />
        </div>
      </section>
      <style>{`
        html,
        body {
          position: fixed;
          width: 100%;
          height: 100%;
          margin: 0;
          padding: 0;
          background-color: #f5f5f5;
          -webkit-overflow-scrolling: touch;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          overscroll-behavior: none;
          user-select: none;
        }

        .wrapper {
          display: grid;
          grid-template-rows: 100px auto 1fr;

          height: calc(100vh - 58px);
        }

        .slider-container {
          position: relative;
          width: 90vw;
          margin: 0 auto;
        }

        .slider div {
          border-radius: 10px;
        }

        i {
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          position: absolute;
          top: 0;
          bottom: 0;
          width: 1%;
          height: 100%;
          z-index: 3;
          cursor: pointer;
          padding: 9px 53.3333px 20px;
        }

        .prev {
          left: 0px;
          -webkit-box-pack: end;
          justify-content: flex-end;
          background-image: linear-gradient(
            270deg,
            rgba(245, 245, 245, 0) 0%,
            rgb(245, 245, 245) 100%
          );
        }

        .next {
          right: 0px;
          background-image: linear-gradient(
            90deg,
            rgba(245, 245, 245, 0) 0%,
            rgb(245, 245, 245) 100%
          );
        }

        h1 {
          font-family: "Ubuntu", sans-serif;
          max-width: 1366px;
          margin: 30px auto;
          padding: 0 5vw;
          box-sizing: border-box;
          font-size: 30px;
          color: #00537a;
        }

        .awssld__bullets button {
          width: 8px;
          height: 8px;
        }

        .awssld__bullets .awssld__bullets--active {
          transform: scale(1);
        }

        .awssld {
          max-height: 647px;
          height: 100%;
          --slider-height-percentage: unset;
          --organic-arrow-thickness: 4px;
          --organic-arrow-height: 30px;
          --loader-bar-color: #00537a;
          --loader-bar-height: 6px;
          --control-button-opacity: 0.7;
          --control-button-hover-opacity: 0.85;
          --control-button-background: transparent;
          --transition-bezier: cubic-bezier(0.5, 0.075, 0.25, 0.95);
          --slider-transition-duration: 575ms;
          --organic-arrow-color: #fff;
          --organic-arrow-border-radius: 10px;
          --control-bullet-color: #00537a;
          --control-bullet-active-color: #ff0000;
          --content-background-color: #f5f5f5;
        }

        @media (max-width: 768px) {
          .wrapper {
            display: grid;
            grid-template-rows: 70px auto 1fr;

            height: calc(var(--vh) * 100);
          }

          .slider-container {
            width: 95vw;
          }

          .awssld__bullets {
            display: none;
          }

          i {
            display: none;
          }

          h1 {
            margin: 0 auto 20px 0;
            font-size: 20px;
          }

          .slider-container {
            width: 95vw;
            padding-bottom: 2.5vw;
          }

          .awssld__controls {
            visibility: visible;
          }

          .awssld {
            --organic-arrow-height: 15px;
          }
        }
      `}</style>
    </>
  );
};

export default Gamarnika;
