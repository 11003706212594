import React, { useState, useEffect } from "react";

const Loader = () => {
  const [load, setLoad] = useState(false);

  useEffect(() => {
    let elem = document.querySelector(".loader");
    let handler = setTimeout(() => {
      setLoad(true);
    }, 3000);

    let handler2 = setTimeout(() => {
      elem.style.display = "none";
    }, 5000);

    return () => {
      clearTimeout(handler);
      clearTimeout(handler2);
    };
  }, []);

  return (
    <div className="loader-container">
      <img className="loader" src="/img-44ae40.png" alt="loader" />
      <style>{`
        .loader-container {
          display: grid;
          place-items: center;

          position: fixed;
          z-index: 20;
          width: 100vw;
          height: calc(var(--vh) * 100);
          background-color: #00537a;
          transform: translate3d(0, ${load ? "-100vh" : "0"}, 0);
          transition: .5s ease;
        }

        .loader {
          width: 200px;
        }

        .loader {
          animation: spin 1s 1s linear infinite;
        }

        @keyframes spin {
          100% {
            transform: rotate(360deg);
          }
        }

        @media (max-width: 768px) {
          .loader {
            width: 100px;
          }
        }
      `}</style>
    </div>
  );
};

export default Loader;
